<template>
  <el-dialog :title="title" :visible.sync="show" class="choose-selectedPlan-importProgress">
    <div class="main flex">
      <div class="icon icon-importing"></div>
      <div class="time">预计用时：{{expectTime}}秒，已用时：{{alreadyTime}}秒</div>
      <div class="text">导入数据共{{sum}},已处理{{haveDone}}条，失败{{faile}}条，正在导入，请稍后...</div>
      <el-button type="primary" size="small" @click="background">后台执行</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  // 导入进度
  name: 'importProgress',
  props: {
    title: {
      tyle: String,
    },
  },
  components: {},
  data() {
    return {
      show: true,
      src: 'https://jxgh.jxtongke.com/uploads/20211015162238.mp41.jpg',
      expectTime: '30', //预计耗时
      alreadyTime: '40', //已经用时
      sum: '1000', //总数据条数
      haveDone: '41', //已经处理条数
      faile: '0', //失败条数
    };
  },
  methods: {
    // 后台执行
    background() {
      console.log('后台执行');
    },
    toggle(show) {
      this.show = show;
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__title {
  font-weight: bold;
}
.el-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .main {
    flex-direction: column;
    .icon {
      font-size: 64px;
      transition: roll 0.3s linear;
      animation: roll 3s linear infinite;
    }
    .time {
      margin-top: 30px;
    }
    .text {
      margin-top: 20px;
      margin-bottom: 50px;
    }
    .btn {
    }
  }
}
@keyframes roll {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
</style>
